<template>
  <div>
    <template v-if="contentFormat === 'editor'">
      <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
      />
    </template>

    <template v-if="contentFormat === 'html'">
      <div style="height: 500px; border: 1px solid #dcdfe6; border-radius: 4px; padding: 8px 0">
        <vue-monaco-editor
          v-model="editorData"
          :options="monacoEditorOptions"
          theme="vs"
          language="html"
          @mount="handleMonacoMount($event)"
        />
      </div>
    </template>

    <div style="float: right">
      <el-radio-group
        v-model="contentFormat"
        size="mini"
      >
        <el-radio-button label="editor">
          Editor
        </el-radio-button>
        <el-radio-button label="html">
          Html
        </el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import ClassicEditor from 'ckeditor5-classic-plus';
import { getToken } from '@/utils/auth';

export default {
  name: 'Editor',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contentFormat: 'editor',
      editor: ClassicEditor,
      editorData: this.value,
      monacoEditorOptions: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
        formatOnLoad: true,
        wordWrap: 'on',
      },
      editorConfig: {
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${process.env.VUE_APP_API_LOCATION}/ckeditor/uploadImage`,
          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,
          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
        toolbar: {
          items: [
            'heading',
            // '|',
            // 'fontFamily',
            // 'fontSize',
            // 'fontColor',
            // 'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'underline',
            // 'strikethrough',
            // 'subscript',
            // 'superscript',
            // 'code',
            // '|',
            'alignment',
            '|',
            'bulletedList',
            'numberedList',
            'indent',
            'outdent',
            '|',
            // 'horizontalLine',
            // 'codeBlock',
            // 'blockQuote',
            'insertTable',
            // '|',
            'link',
            'uploadImage',
            'toggleImageCaption',
            'mediaEmbed',
            '|',
            'undo',
            'redo',
            'findAndReplace',
            // 'imageTextAlternative',
            // 'toggleImageCaption',
          ],
          shouldNotGroupWhenFull: true,
        },
      },
    };
  },
  watch: {
    value(newVal) {
      this.editorData = newVal;
    },
    editorData(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    handleMonacoMount(editor) {
      editor.setValue(this.editorData);
      setTimeout(() => {
        editor.getAction('editor.action.formatDocument').run();
      }, 100);
    },
  },
};
</script>
